import {
  DeviceCategories,
  FilterSize,
  db,
  getCompanies,
} from "@vuumly-common/common";
import Color from "color";
import { Dataset, useOperatorMode, useUserContext } from "../index";

const red = {
  100: "#fef2f2",
  200: "#fee2e2",
  300: "#fca5a5",
  400: "#f87171",
  500: "#ef4444",
  600: "#dc2626",
  700: "#b91c1c",
  800: "#991b1b",
  900: "#7f1d1d",
};

const sky = {
  50: "#f0f9ff",
  100: "#e0f2fe",
  200: "#bae6fd",
  300: "#7dd3fc",
  400: "#38bdf8",
  500: "#0ea5e9",
  600: "#0284c7",
  700: "#0369a1",
  800: "#075985",
  900: "#0c4a6e",
};

const emerald = {
  50: "#ecfdf5",
  100: "#d1fae5",
  200: "#a7f3d0",
  300: "#6ee7b7",
  400: "#34d399",
  500: "#10b981",
  600: "#059669",
  700: "#047857",
  800: "#065f46",
  900: "#064e3b",
};

const yellow = {
  50: "#fefce8",
  100: "#fef9c3",
  200: "#fef08a",
  300: "#fde047",
  400: "#facc15",
  500: "#eab308",
  600: "#ca8a04",
  700: "#a16207",
  800: "#854d0e",
  900: "#713f12",
};

const orange = {
  50: "#fff7ed",
  100: "#ffedd5",
  200: "#fed7aa",
  300: "#fdba74",
  400: "#fb923c",
  500: "#f97316",
  600: "#ea580c",
  700: "#c2410c",
  800: "#9a3412",
  900: "#7c2d12",
};

const amber = {
  50: "#fffbeb",
  100: "#fef3c7",
  200: "#fde68a",
  300: "#fcd34d",
  400: "#fbbf24",
  500: "#f59e0b",
  600: "#d97706",
  700: "#b45309",
  800: "#92400e",
  900: "#78350f",
};

const pink = {
  50: "#fdf2f8",
  100: "#fce7f3",
  200: "#fbcfe8",
  300: "#f9a8d4",
  400: "#f472b6",
  500: "#ec4899",
  600: "#db2777",
  700: "#be185d",
  800: "#9d174d",
  900: "#831843",
};

const purple = {
  50: "#faf5ff",
  100: "#f3e8ff",
  200: "#e9d5ff",
  300: "#d8b4fe",
  400: "#c084fc",
  500: "#a855f7",
  600: "#9333ea",
  700: "#7e22ce",
  800: "#6b21a8",
  900: "#581c87",
};

const green = {
  50: "#f0fdf4",
  100: "#dcfce7",
  200: "#bbf7d0",
  300: "#86efac",
  400: "#4ade80",
  500: "#22c55e",
  600: "#16a34a",
  700: "#15803d",
  800: "#166534",
  900: "#14532d",
};

const colors = {
  slate: "#475569",
  gray: "#4b5563",
  zinc: "#52525b",
  neutral: "#525252",
  red: red[600],
  orange: "#ea580c",
  amber: "#d97706",
  yellow: yellow[600],
  lime: "#65a30d",
  green: "#16a34a",
  emerald: emerald[600],
  teal: "#0d9488",
  cyan: "#0891b2",
  sky: sky[600],
  blue: "#2563eb",
  indigo: "#4f46e5",
  violet: "#7c3aed",
  purple: purple[600],
  fuchsia: "#c026d3",
  pink: pink[600],
  rose: "#e11d48",
  white: "#ffffff",
};

const slate = {
  100: "#f1f5f9",
  200: "#e2e8f0",
  300: "#cbd5e1",
  400: "#94a3b8",
  500: "#64748b",
  600: "#475569",
  700: "#334155",
  800: "#1e293b",
  900: "#0f172a",
  950: "#020617",
};

const operatorDeviceColor = 900;
const defaultDeviceColor = 500;

const theme = {
  colors: {
    accent: colors.emerald,

    backgroundColor: colors.white,
    lightBackgroundOnBackground: slate[300],
    textOnLightBackground: slate[600],

    operatorColor: colors.emerald,
    hoverOperatorColor: emerald[700],

    otherOperatorLineColor: slate[400],
    hoverOtherOperatorLineColor: slate[500],

    otherOperatorBarColor: slate[300],
    hoverOtherOperatorBarColor: slate[400],

    car: red[defaultDeviceColor],
    bike: sky[defaultDeviceColor],
    scooter: emerald[defaultDeviceColor],
    cargo: yellow[defaultDeviceColor],
    e_bike: pink[defaultDeviceColor],
    moped: purple[defaultDeviceColor],

    // operator_car: red[operatorDeviceColor],
    // operator_bike: sky[operatorDeviceColor],
    // operator_scooter: emerald[operatorDeviceColor],
    // operator_cargo: yellow[operatorDeviceColor],
    // operator_e_bike: pink[operatorDeviceColor],
    // operator_moped: purple[operatorDeviceColor],
  },
};

const charts = {
  colors: {
    primary: colors.emerald,
    hover: emerald[700],
  },
};

const datasets: Record<string, string> = {
  "ride_length_group:_5": colors.slate,
  "ride_length_group:5_10": colors.emerald,
  "ride_length_group:10_15": colors.blue,
  "ride_length_group:15_20": colors.purple,
  "ride_length_group:20_30": colors.pink,
  "ride_length_group:30_60": colors.amber,
  "ride_length_group:60_120": colors.lime,
  "ride_length_group:120_300": colors.cyan,
  "ride_length_group:300_720": colors.indigo,
  "ride_length_group:720_": colors.rose,
  "ride_length_group:120_1440": colors.pink,
  "ride_length_group:1440_4320": colors.purple,
  "ride_length_group:4320_": colors.teal,

  e_bike: theme.colors.e_bike,
  car: theme.colors.car,
  bike: theme.colors.bike,
  scooter: theme.colors.scooter,
  cargo: theme.colors.cargo,
  moped: theme.colors.moped,

  ride_length_avg_bike: theme.colors.bike,
  ride_length_avg_car: theme.colors.car,
  ride_length_avg_scooter: theme.colors.scooter,
  ride_length_avg_cargo: theme.colors.cargo,
  ride_length_avg_e_bike: theme.colors.e_bike,
  ride_length_avg_moped: theme.colors.moped,

  ride_count_operator: theme.colors.operatorColor,

  micromobility_rides: theme.colors.scooter,
  micromobility_median: theme.colors.textOnLightBackground,

  carsharing_rides: theme.colors.car,
  carsharing_median: theme.colors.textOnLightBackground,
};

interface Props {
  ignoreOperatorMode?: boolean;
  ignoreDatasetColor?: boolean;
  chartType?: "bar" | "line";
}

interface ItemColor {
  color: string;
  hover: string;
}

const companies = getCompanies();

const companyColors: Record<number, ItemColor> = {
  0: {
    color: slate[500],
    hover: slate[600],
  },
  1: {
    color: orange[500],
    hover: orange[600],
  },
  2: {
    color: pink[500],
    hover: pink[600],
  },
  3: {
    color: yellow[800],
    hover: pink[900],
  },
  4: {
    color: green[500],
    hover: green[600],
  },
  // Ride
  6: {
    color: "#D73C2D",
    hover: Color("#D73C2D").darken(0.1).toString(),
  },
  // Skok
  7: {
    color: "#38A1DD",
    hover: Color("#38A1DD").darken(0.1).toString(),
  },
  // Nextbike
  8: {
    color: "#204F98",
    hover: Color("#204F98").darken(0.1).toString(),
  },
  // ox
  9: {
    color: purple[500],
    hover: purple[600],
  },
  // beast
  11: {
    color: slate[800],
    hover: slate[900],
  },
  // panek
  16: {
    color: "#E30614",
    hover: Color("#E30614").darken(0.1).toString(),
  },
  // 4mobility
  17: {
    color: "#FF6A39",
    hover: Color("#FF6A39").darken(0.1).toString(),
  },
  // traficar
  18: {
    color: "#7214AC",
    hover: Color("#7214AC").darken(0.1).toString(),
  },
  // tuul
  23: {
    color: "#8BCDBA",
    hover: Color("#8BCDBA").darken(0.1).toString(),
  },
  // share now
  25: {
    color: "#0CA1E2",
    hover: Color("#0CA1E2").darken(0.1).toString(),
  },
  // udrive
  26: {
    color: "#0CA34A",
    hover: Color("#0CA34A").darken(0.1).toString(),
  },
  // ekar
  27: {
    color: "#32C5F4",
    hover: Color("#32C5F4").darken(0.1).toString(),
  },
  // SixtShare
  29: {
    color: "#FF6A39",
    hover: Color("#FF6A39").darken(0.1).toString(),
  },
  // Miles
  30: {
    color: slate[700],
    hover: slate[800],
  },
};

const devicesTypes: Record<string, ItemColor> = {
  car: {
    color: red[500],
    hover: red[600],
  },
  bike: {
    color: sky[500],
    hover: sky[600],
  },
  scooter: {
    color: emerald[500],
    hover: emerald[600],
  },
  cargo: {
    color: yellow[500],
    hover: yellow[600],
  },
  e_bike: {
    color: pink[500],
    hover: pink[600],
  },
  moped: {
    color: purple[500],
    hover: purple[600],
  },
};

function getDeviceTypeColors(device_type = "car") {
  return devicesTypes[device_type] || devicesTypes["car"];
}

export const useTheme = () => {
  const { operatorMode } = useOperatorMode();
  const { operator_id } = useUserContext().userState;

  function getRideLengthGroupColor(rideLengthGroup: string) {
    return (
      datasets[`ride_length_group:${rideLengthGroup}`] || theme.colors.accent
    );
  }

  function getDeviceSizeColor(size: string) {
    if (size === FilterSize.Size_Small.tag) {
      return {
        color: red[500],
        hover: red[600],
      };
    }
    if (size === FilterSize.Size_Medium.tag) {
      return {
        color: yellow[500],
        hover: yellow[600],
      };
    }
    if (size === FilterSize.Size_Large.tag) {
      return {
        color: emerald[500],
        hover: emerald[600],
      };
    }
    return {
      color: slate[500],
      hover: slate[600],
    };
  }

  function getDeviceBodyTypeColor(size: string) {
    if (size === DeviceCategories.Hatchbacks.tag) {
      return {
        color: red[500],
        hover: red[600],
      };
    }

    if (size === DeviceCategories.Sedan.tag) {
      return {
        color: yellow[500],
        hover: yellow[600],
      };
    }

    if (size === DeviceCategories.Suv.tag) {
      return {
        color: purple[500],
        hover: purple[600],
      };
    }

    if (size === DeviceCategories.Estates.tag) {
      return {
        color: sky[500],
        hover: sky[600],
      };
    }

    if (size === DeviceCategories.Crossover.tag) {
      return {
        color: green[500],
        hover: green[600],
      };
    }

    if (size === DeviceCategories.Small.tag) {
      return {
        color: pink[500],
        hover: pink[600],
      };
    }

    return {
      color: slate[500],
      hover: slate[600],
    };
  }

  function getCompanyColors(companyId = 0) {
    if (operator_id === String(companyId)) {
      return {
        color: theme.colors.operatorColor,
        hover: theme.colors.hoverOperatorColor,
      };
    }
    return companyColors[companyId] || companyColors[0];
  }

  function getDatasetColor(dataset: Dataset, props?: Props) {
    const {
      ignoreOperatorMode,
      ignoreDatasetColor,
      chartType = "line",
    } = props || {};

    if (dataset.device_type && !operatorMode) {
      return datasets[dataset.device_type];
    }

    if (
      !ignoreOperatorMode &&
      operatorMode &&
      dataset?.usage_type === db.models.ColumnType.Metrics
    ) {
      if (chartType === "line") {
        return theme.colors.otherOperatorLineColor;
      } else if (chartType === "bar") {
        return theme.colors.otherOperatorBarColor;
      }
    }

    if (ignoreDatasetColor) {
      return theme.colors.accent;
    }

    if (!dataset?.raw_label) return theme.colors.accent;
    return datasets[dataset.raw_label] || theme.colors.accent;
  }

  return {
    theme,
    charts,
    getDatasetColor,
    getCompanyColors,
    getDeviceTypeColors,
    getRideLengthGroupColor,
    getDeviceSizeColor,
    getDeviceBodyTypeColor,

    accentColor: theme.colors.accent,
    accentColorHover: emerald[700],

    // operatorColor: theme.colors.operatorColor,
    // hoverOperatorColor: theme.colors.hoverOperatorColor,
    negativeLineChartColor: red[500],
    hoverNegativLineChartColor: red[600],

    positiveLineChartColor: emerald[500],
    hoverPositiveLineChartColor: emerald[600],

    postiveBarChartColor: emerald[500],
    hoverPositiveBarChartColor: emerald[600],

    negativeBarChartColor: red[500],
    hoverNegativeBarChartColor: red[600],

    otherOperatorBarColor: theme.colors.otherOperatorBarColor,
    hoverOtherOperatorBarColor: theme.colors.hoverOtherOperatorBarColor,

    otherOperatorLineColor: theme.colors.otherOperatorLineColor,
    hoverOtherOperatorLineColor: theme.colors.hoverOtherOperatorLineColor,
  };
};
