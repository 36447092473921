import { useQuery as tanstackQuery } from "@tanstack/react-query";
import { DeviceEnum } from "@vuumly-common/common";
import axios from "axios";
import Color from "color";
import { BaseMap } from "components/Map";
import { MapContextProvider, useMapContext } from "context/MapContext";
import {
  useCenterMap,
  useCompany,
  useCountry,
  useDeviceType,
  usePageTitle,
  useTheme,
} from "hooks";
import { click } from "ol/events/condition";
import "ol/ol.css";
import { useEffect, useRef, useState } from "react";
import { DashboardHeader } from "../../components/Header";
import { MapSidebar } from "../../components/Sidebar";
import { TextH5, TextMuted, TextSmall } from "../../components/Typography/Text";
import { RoutePathEnum } from "../../controllers/router";
import { ol } from "../../utils";
import {
  RealtimeDeviceFeature,
  RealtimeDeviceLayer,
} from "../Distribution/Layers";

interface Device {
  company_id: number;
  group_id: number;
  company: string;
  timestamp: string;
  category: string[];
  group: string;
  location: {
    lat: number;
    lng: number;
  };
  id: string;
  uid: string;
  price: string;
  image: string;
  device_type: string;
  charge: number;
  record_id: string;
  suburb: string;
  country_code: string;
  distinct_place: string;
  licensePlate: string;
  model: string;
  name: string;
}

let styles: Record<number, ol.Style> = {};

const selectedStyle = new ol.Style({
  image: new ol.Circle({
    radius: 12,
    fill: new ol.Fill({ color: "red" }),
    stroke: new ol.Stroke({ color: "rgba(255,0,0,.2)", width: 15 }),
  }),
  zIndex: 10,
});

const selectClick = new ol.Select({
  condition: click,
  style: selectedStyle,
});

const Content = () => {
  const { selectedCompanies } = useCompany();
  useCenterMap();
  const { isMicromobilityActive, selectedDeviceTypes } = useDeviceType();
  const { mapInstance } = useMapContext();
  const { getCompanyColors } = useTheme();
  const { selectedCountryCode } = useCountry();
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
  const [count, setCount] = useState(0);

  const { data, isLoading } = tanstackQuery<Device[]>({
    staleTime: 60000,
    queryKey: ["availableDevices"],
    queryFn: async () => {
      const res = await axios(
        "https://api.vuumly.com/api/availableDevices",
        {}
      );
      return res.data;
    },
  });

  const vectorSource = useRef(new ol.VectorSource());
  const vectorLayer = useRef(
    new RealtimeDeviceLayer({
      source: vectorSource.current,
      style: selectedStyle,
    })
  );

  useEffect(() => {
    return () => {
      if (vectorSource.current) {
        // vectorSource.current.clear();
      }
    };
  }, [data, selectedDeviceTypes, selectedCompanies]);

  useEffect(() => {
    if (mapInstance) {
      mapInstance?.addLayer(vectorLayer.current);

      mapInstance.addInteraction(selectClick);

      selectClick.on("select", (e) => {
        if (e.selected.length === 0) {
          setSelectedDevice(null);
          return;
        } else {
          setSelectedDevice(e.selected[0].getProperties() as Device);
        }
      });
    }
    return () => {
      if (mapInstance) {
        mapInstance?.removeLayer(vectorLayer.current);
        mapInstance?.removeInteraction(selectClick);
      }
    };
  }, [mapInstance]);

  useEffect(() => {
    return () => {
      if (vectorLayer.current) {
        mapInstance?.removeLayer(vectorLayer.current);
      }
      if (vectorSource.current) {
        vectorSource.current.clear();
      }
      if (selectClick) {
        mapInstance?.removeInteraction(selectClick);
      }
    };
  }, []);

  useEffect(() => {
    if (!selectedCountryCode) {
      return;
    }

    const filtered = data?.filter((d) => {
      if (d.country_code !== selectedCountryCode) return false;
      if (isMicromobilityActive) {
        if (d.device_type === DeviceEnum.Car) return false;
        if (d.device_type === DeviceEnum.Cargo) return false;
      } else {
        if (d.device_type === DeviceEnum.Scooter) return false;
        if (d.device_type === DeviceEnum.Bike) return false;
        if (d.device_type === DeviceEnum.Moped) return false;
        if (d.device_type === DeviceEnum.EBike) return false;
      }

      return (
        d.country_code === selectedCountryCode &&
        (selectedDeviceTypes.length === 0 ||
          selectedDeviceTypes.includes(d.device_type as DeviceEnum)) &&
        (selectedCompanies.length === 0 ||
          selectedCompanies.includes(String(d.company_id)))
      );
    });

    vectorSource.current.clear();

    // add filtered devices to map as markers
    filtered?.forEach((d) => {
      const marker = new RealtimeDeviceFeature({
        geometry: new ol.Point(ol.fromLonLat([d.location.lng, d.location.lat])),
      });

      if (!styles[d.company_id]) {
        styles[d.company_id] = new ol.Style({
          image: new ol.Circle({
            radius: 3,
            fill: new ol.Fill({
              color: Color(getCompanyColors(d.company_id).color)
                // .alpha(0.5)
                .string(),
            }),
          }),
        });
      }

      marker.setStyle(styles[d.company_id]);
      marker.setProperties(d);

      vectorSource.current.addFeature(marker);
    });
    setCount(filtered?.length || 0);
  }, [data, selectedCompanies, selectedDeviceTypes]);

  return (
    <>
      {!isLoading && (
        <MapSidebar>
          {selectedDevice && (
            <div className="p-4 space-y-4 border-b">
              <div>
                <TextH5>Selected device</TextH5>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <TextMuted>Company</TextMuted>
                <TextSmall>{selectedDevice.company}</TextSmall>

                <TextMuted>Charge</TextMuted>
                <TextSmall>{selectedDevice.charge}%</TextSmall>

                <TextMuted>License plate</TextMuted>
                <TextSmall>{selectedDevice.licensePlate}</TextSmall>

                <TextMuted>Model</TextMuted>
                <TextSmall>{selectedDevice.name}</TextSmall>

                <TextSmall>Price</TextSmall>
                <TextSmall>{selectedDevice.price}</TextSmall>
              </div>
              <img src={selectedDevice.image} width={200} />
            </div>
          )}
          {!selectedDevice && (
            <div className="p-4 space-y-4 ">
              <div className=" bg-slate-100 rounded-md border-1 flex justify-center items-center h-[200px]">
                <TextMuted>Select a vehicle to view details</TextMuted>
              </div>
            </div>
          )}
          <div className="p-4 border-b">
            <TextH5>Total vehicle count {count}</TextH5>
          </div>
        </MapSidebar>
      )}
      <BaseMap isLoading={isLoading} />
    </>
  );
};

export function LivePage() {
  const title = usePageTitle(RoutePathEnum.LiveMap);
  return (
    <>
      <div className="max-w-7xl mx-auto p-6 w-full">
        <DashboardHeader className="mb-0" title={title} />
      </div>
      <div className="flex flex-1 relative">
        <MapContextProvider>
          <Content />
        </MapContextProvider>
      </div>
    </>
  );
}
